import { Container, Row, Col } from 'react-bootstrap'
import styles from './Footer.module.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Wildberries from '../Wildberries/Wildberries'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <Row className="align-items-center justify-items-center">
          {/* <Wildberries /> */}
          <Col size={12} sm={6}>
            <img
              src="/image/nelinkaIcon.svg"
              alt="Logo"
              className={styles.imgLogo}
            />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className={styles.socialIcon}>
              <a
                target="_blank"
                href="https://www.facebook.com/p/Nelinka-100045246413209/?locale=nn_NO">
                <img src="/image/nav-icon2.svg" alt="Icon" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/nelinka_for_you/">
                <img src="/image/nav-icon3.svg" alt="Icon" />
              </a>
            </div>
            <p>Copyright 2023. All Rights Reserved </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
